import ReactECharts from 'echarts-for-react';
import { observer } from 'mobx-react';
import React from 'react';
import type { OutlierSkuStatistics, Legend } from '../../interface';
import { LegendData } from '../legendData/index';
import { titleBaseOpts, graphicBaseOpts, basicPie, realPieOpts, ReactEChartsStyle } from './baseOptions';
import styles from './index.less';

interface Props {
  data: OutlierSkuStatistics;
  time: string;
}

const SkuPieCharts = observer((props: Props) => {
  const { data, time } = props;
  const offShelfSkuNm = data && data.offShelfSkuNm ? data.offShelfSkuNm : 0;
  const offShelfSkuPercent = data && data.offShelfSkuPercent ? data.offShelfSkuPercent : 0;
  const lackSkuNum = data && data.lackSkuNum ? data.lackSkuNum : 0;
  const lackSkuPercent = data && data.lackSkuPercent ? data.lackSkuPercent : 0;
  const closedSkuNum = data && data.closedSkuNum ? data.closedSkuNum : 0;
  const closedSkuPercent = data && data.closedSkuPercent ? data.closedSkuPercent : 0;
  const lostSkuNum = data && data.lostSkuNum ? data.lostSkuNum : 0;
  const lostSkuPercent = data && data.lostSkuPercent ? data.lostSkuPercent : 0;
  const otherSkuNum = data && data.otherSkuNum ? data.otherSkuNum : 0;
  const otherSkuPercent = data && data.otherSkuPercent ? data.otherSkuPercent : 0;
  const outlierSkuTotalNum = data && data.outlierSkuTotalNum ? data.outlierSkuTotalNum : 0;
  const outlierSkuPercent = data && data.outlierSkuPercent ? data.outlierSkuPercent : 0;

  const legendData: Legend[] = [
    {
      label: '下架',
      iconColor: '#D32301 ',
      num: offShelfSkuNm,
      percent: offShelfSkuPercent,
    },
    {
      label: '缺货',
      iconColor: '#F2270A',
      num: lackSkuNum,
      percent: lackSkuPercent,
    },
    {
      label: '供应商未开店',
      iconColor: '#F05741',
      num: closedSkuNum,
      percent: closedSkuPercent,
    },
    {
      label: '条码丢失',
      iconColor: '#FB7307',
      num: lostSkuNum,
      percent: lostSkuPercent,
    },
    {
      label: '其他',
      iconColor: '#FF9948',
      num: otherSkuNum,
      percent: otherSkuPercent,
    },
  ];

  const option = {
    tooltip: { show: false },
    title: { // 饼图中心文字第一行
      ...titleBaseOpts,
      text: `${outlierSkuPercent}%`,
      textStyle: {
        ...titleBaseOpts.textStyle,
        color: '#FAAC53',
      },
    },
    graphic: { // 饼图中心文字第二行
      ...graphicBaseOpts,
      style: {
        ...graphicBaseOpts.style,
        text: '异常sku占比',
      },
    },
    colorBy: 'data',
    animation: true,
    overflow: 'truncate',
    series: [
      basicPie,
      {
        ...realPieOpts,
        name: '异常sku',
        label: { show: false },
        color: [
          'rgba(255,255,255,0)',
          '#D32301',
          '#F2270A',
          '#F05741',
          '#FB7307',
          '#FF9948',
        ],
        data: [
          {
            value: 100 - Number(outlierSkuPercent),
            name: '正常sku',
          },
          {
            value: offShelfSkuPercent,
            name: '下架',
          },
          {
            value: lackSkuPercent,
            name: '缺货',
          },
          {
            value: closedSkuPercent,
            name: '供应商未开店',
          },
          {
            value: lostSkuPercent,
            name: '条码丢失',
          },
          {
            value: otherSkuPercent,
            name: '其他',
          },
        ],
      },
    ],
  };

  const gotoDetailPage = (): void => {
    window.top.egenie.openTab(`/egenie-ts-pgms/abnormalGoods?time=${time}`, '8004', '异常商品');
  };

  return (
    <div className={styles.chartsArea}>
      <div className={styles.chartsTitle}>
        <span>
          异常sku
        </span>
        <span
          className={styles.red}
          onClick={gotoDetailPage}
        >
          异常sku数
          {' '}
          {outlierSkuTotalNum}
          <span className={`${styles.arrowIcon} icon-home_xq`}/>
        </span>
      </div>
      <div className={styles.chartsAndLegend}>
        <ReactECharts
          lazyUpdate
          notMerge
          option={option}
          style={ReactEChartsStyle}
        />
        <LegendData
          data={legendData}
          title={`异常sku数 ${outlierSkuTotalNum}`}
          unit=""
        />
      </div>
    </div>
  );
});

export { SkuPieCharts };
