import { Select } from 'antd';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import React from 'react';
import cart from '../../assets/images/cart.png';
import { api } from '../../utils';
import { LayoutGuide, LayoutStore } from '../../utils/layout';
import Dashboard from './dashboard/index';
import styles from './index.less';
import type { Opera, WareHouse } from './interface';
import NotificationDrawerStore from '../notificationDrawer/store';
import { SystemType } from '../components/noticeBadge/constant';
import NoticeBadge from '../components/noticeBadge';

const notificationCenterStore = new NotificationDrawerStore({ parent: this });

const layoutStore = new LayoutStore({
  project: {
    name: '',
    value: 'egenie-ts-pgms',
  },
});
layoutStore.handleInit();

interface IState {
  warehouseList: WareHouse[];
  currentWareHouse: string;
  isPOS: boolean;
  isXuanKuan: boolean;
  showRestDialog: boolean;
  staticData?;
  site: string;
}

// 导航 示例
export default class LayoutTest extends React.Component {
  public state: IState = {
    warehouseList: [],
    currentWareHouse: '',
    isPOS: false,
    isXuanKuan: false,
    showRestDialog: false,
    staticData: {},
    site: '',
  };

  async componentDidMount(): Promise<void> {
    const res: { tenantType?; status: string; } = await request({ url: api.user });
    const isPOS = res.tenantType.includes('100002');
    const isXuanKuan = res.tenantType.includes('100015');
    this.setState({
      isPOS,
      isXuanKuan,
    });
    if (isPOS) {
      this.queryWarehouse();
    }
    this.querySite();
  }

  public queryWarehouse(): void {
    request({ url: api.getWarehouses })
      .then((res: { data?; status: string; }) => {
        if (!res.data || res.data.length === 0) {
          return;
        }
        this.setState({
          warehouseList: res.data,
          currentWareHouse: res.data[0].shopAndWarehouseId,
        }, () => {
          layoutStore.setSrcParams(this.getSrcParams());
        });

        // 首页 快捷方式使用
        sessionStorage.setItem('currentWareHouse', res.data[0].shopAndWarehouseId);
      });
  }

  public querySite = async(): Promise<void> => {
    const res: BaseData<{ siteId: number; siteName: string; }> = await request({ url: api.pgmsSite });
    this.setState({ site: res.data.siteName });
  };

  public userInfoLeft = (): JSX.Element => {
    const {
      currentWareHouse,
      warehouseList,
      isPOS,
      isXuanKuan,
    } = this.state;
    return (
      <div>
        <NoticeBadge systemType={SystemType.PGMS_PC}/>

        {isPOS && (
          <>
            <Select
              bordered={false}
              onChange={this.handleWarehouseChange.bind(this)}
              style={{ width: 120 }}
              value={currentWareHouse}
            >
              {warehouseList.map((item) => (
                <Select.Option
                  key={item.shopAndWarehouseId}
                  value={item.shopAndWarehouseId}
                >
                  {item.shopAndWarehouseName}
                </Select.Option>
              ))}

            </Select>
            <span className={styles.splitIcon}>
              |
            </span>
          </>
        )}
        {isXuanKuan && (
          <span
            className={styles.shoppingCart}
            onClick={() => top.egenie.openTab('/egenie-ts-vogue/shoppingCart/index', 'shoppingCart', '进货车')}
          >
            <img
              src={cart}
              style={{
                width: 17,
                height: 17,
                marginBottom: 2,
                marginLeft: 0,
              }}
            />
            <span className={styles.cartText}>
              进货车
            </span>
            <span className={styles.splitIcon}>
              |
            </span>
          </span>
        )}
        <span>
          <span>
            {this.state.site}
          </span>
          <span className={styles.splitIcon}>
            |
          </span>
        </span>
      </div>
    );
  };

  public userInfoRight = (): Opera[] => {
    return [
      {
        id: 'export',
        name: '导入导出任务中心',
        callback: () => top.egenie.openTab('/egenie-ts-baseinfo/exportList/index', 'export_task_center', '导入导出任务中心', 'zc_pfs'),
      },
    ];
  };

  public getSrcParams = (): Array<{ id: number; params: string; }> => {
    const result = [];

    const menus = [
      506, // 销售开单
      507, // 退货开单
      508, // 开单查询
      510, // 退货查询
      645, // POS入库
      646, // POS出库
      647, // POS盘点
      648, // POS调拨
      2108, // POS客服应收
      253, // 客户管理
      310, // 选款订单
      60088, // 客户商品查询
      60085, // 销退合一
    ];
    sessionStorage.setItem('warehouseMenu', JSON.stringify(menus));
    menus.forEach((item) => {
      result.push({
        id: item,
        params: `shopAndWarehouseId=${this.state.currentWareHouse}`,
      });
    });
    return result;
  };

  public handleWarehouseChange(value: string): void {
    this.setState({ currentWareHouse: value }, () => {
      layoutStore.setSrcParams(this.getSrcParams());
    });
    sessionStorage.setItem('currentWareHouse', value);

    // 切换仓库后关闭所有iframe  留在首页
    const iframes = document.getElementsByTagName('iframe');
    for (let i = 1; i < iframes.length; i++) {
      const element = iframes[i];
      window.egenie.closeTab(element.id);
    }
  }

  public getLogoImg = (): JSX.Element => {
    return (
      <img
        onDragStart={(e) => {
          e.preventDefault();
        }}
        src={`${api.oss}/images/pgmsLogo.png`}
        style={{ width: '100%' }}
      />
    );
  };

  render(): JSX.Element {
    const { isPOS } = this.state;
    return (
      <div>
        <LayoutGuide
          defaultDashboard={<Dashboard/>}
          haveDashboard={false}
          logoImg={this.getLogoImg()}
          store={layoutStore}
          userInfoLeft={this.userInfoLeft()}
          userInfoRight={this.userInfoRight()}
        />

      </div>
    );
  }
}
