import ReactECharts from 'echarts-for-react';
import { observer } from 'mobx-react';
import React from 'react';
import type { UserStatistics, Legend, ReturnUserStatistics } from '../../interface';
import { LegendData } from '../legendData/index';
import { titleBaseOpts, graphicBaseOpts, basicPie, realPieOpts, ReactEChartsStyle } from './baseOptions';
import styles from './index.less';

interface Props {
  data: Partial<UserStatistics & ReturnUserStatistics> ;
  activeKey: '1' | '2'; // 1拿货，2退货
}

const UserPieCharts = observer((props: Props) => {
  const { data, activeKey } = props;

  // 拿货
  const finishUserNum = data && data.finishUserNum ? data.finishUserNum : 0;
  const finishUserPercent = data && data.finishUserPercent ? data.finishUserPercent : 0;
  const hangUserNum = data && data.hangUserNum ? data.hangUserNum : 0;
  const hangUserPercent = data && data.hangUserPercent ? data.hangUserPercent : 0;
  const userNum = data && data.userNum ? data.userNum : 0;

  // 退货
  const completeUserNum = data && data.completeUserNum ? data.completeUserNum : 0;
  const completeUserPercent = data && data.completeUserPercent ? data.completeUserPercent : 0;
  const unCompleteUserNum = data && data.unCompleteUserNum ? data.unCompleteUserNum : 0;
  const unCompleteUserPercent = data && data.unCompleteUserPercent ? data.unCompleteUserPercent : 0;
  const returnUserNum = data && data.userNum ? data.userNum : 0;

  const legendData: Legend[] = [
    {
      label: '已完成拿货员',
      iconColor: '#1978FF',
      num: finishUserNum,
      percent: finishUserPercent,
    },
    {
      label: '未完成拿货员',
      iconColor: '#F2F2F2',
      color: '#2B2E3E',
      num: hangUserNum,
      percent: hangUserPercent,
    },
  ];

  const returnLegendData: Legend[] = [
    {
      label: '已完成退货员',
      iconColor: '#1978FF',
      num: completeUserNum,
      percent: completeUserPercent,
    },
    {
      label: '未完成退货员',
      iconColor: '#F2F2F2',
      color: '#2B2E3E',
      num: unCompleteUserNum,
      percent: unCompleteUserPercent,
    },
  ];
  
  const option = {
    tooltip: { show: false },
    title: { // 饼图中心文字第一行
      ...titleBaseOpts,
      text: `${finishUserPercent}%`,
    },
    graphic: { // 饼图中心文字第二行
      ...graphicBaseOpts,
      style: {
        ...graphicBaseOpts.style,
        text: '已完成拿货员',
      },
    },
    colorBy: 'data',
    animation: true,
    series: [
      basicPie,
      {
        name: '拿货员进度',
        ...realPieOpts,
        data: [
          {
            value: hangUserPercent,
            name: '未完成拿货员',
          },
          {
            value: finishUserPercent,
            name: '已完成拿货员',
          },
        ],
      },
    ],
  };

  const returnOption = {
    tooltip: { show: false },
    title: { // 饼图中心文字第一行
      ...titleBaseOpts,
      text: `${completeUserPercent}%`,
    },
    graphic: { // 饼图中心文字第二行
      ...graphicBaseOpts,
      style: {
        ...graphicBaseOpts.style,
        text: '退货员完成',
      },
    },
    colorBy: 'data',
    animation: true,
    series: [
      basicPie,
      {
        name: '退货员进度',
        ...realPieOpts,
        data: [
          {
            value: unCompleteUserPercent,
            name: '未完成退货员',
          },
          {
            value: completeUserPercent,
            name: '已完成退货员',
          },
        ],
      },
    ],
  };
  return (
    <div className={styles.chartsArea}>
      <div className={styles.chartsTitle}>
        {activeKey === '1' ? '拿货员进度' : '退货员进度'}
      </div>
      <div className={styles.chartsAndLegend}>
        <ReactECharts
          lazyUpdate
          notMerge
          option={activeKey === '1' ? option : returnOption}
          style={ReactEChartsStyle}
        />
        <LegendData
          data={activeKey === '1' ? legendData : returnLegendData}
          title={activeKey === '1' ? `拿货员数 ${userNum}人` : `退货员数 ${returnUserNum}人`}
        />
      </div>
    </div>
  );
});

export { UserPieCharts };
