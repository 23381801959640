const titleBaseOpts = { // 饼图中心文字第一行
  left: 150,
  top: 140,
  textStyle: {
    textAlign: 'center',
    color: '#1978FF',
    fontSize: 16,
    fontWeight: 500,
  },
};

const graphicBaseOpts = { // 饼图中心文字第二行
  type: 'text',
  left: 129,
  top: 170,
  z: 2,
  zlevel: 100,
  style: {
    textAlign: 'center',
    fontSize: 14,
    color: '#2B2E3F',
  },
};

const basicPie = { // 基础pie图，无需图例
  type: 'pie',
  radius: [
    '105',
    '110',
  ], // 改变圆圈大小和位置的
  clockwise: false,
  label: { show: false },
  labelLine: { show: false },
  color: ['#F1F2F3'],
  center: [
    170,
    170,
  ],
  data: [
    {
      value: 0,
      name: '',
    },
  ],
};

const realPieOpts = { // 真正的pie图
  type: 'pie',
  radius: [
    '90',
    '115',
  ],
  center: [
    170,
    170,
  ],
  stillShowZeroSum: false,
  clockwise: false,
  animationDelay: 500,
  animationDuration: 2000,
  hoverAnimation: false,
  legendHoverLink: false,
  avoidLabelOverlap: false,
  label: { show: false },
  labelLine: { show: false },
  color: [
    'rgba(255,255,255,0)',
    '#1978FF',
  ],
};

const ReactEChartsStyle = {
  height: 340,
  width: 340,
  marginRight: '10%',
};

export { titleBaseOpts, graphicBaseOpts, basicPie, realPieOpts, ReactEChartsStyle };
