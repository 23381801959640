import ReactECharts from 'echarts-for-react';
import { observer } from 'mobx-react';
import React from 'react';
import type { VendorStatistics, Legend, ReturnVendorStatistics } from '../../interface';
import { LegendData } from '../legendData/index';
import { titleBaseOpts, graphicBaseOpts, basicPie, realPieOpts, ReactEChartsStyle } from './baseOptions';
import styles from './index.less';

interface Props {
  data: Partial<VendorStatistics & ReturnVendorStatistics>;
  activeKey: '1' | '2'; // 1拿货，2退货
}

const VendorPieCharts = observer((props: Props) => {
  const { data, activeKey } = props;

  // 拿货
  const finishVendorNum = data && data.finishVendorNum ? data.finishVendorNum : 0;
  const finishVendorPercent = data && data.finishVendorPercent ? data.finishVendorPercent : 0;
  const hangVendorNum = data && data.hangVendorNum ? data.hangVendorNum : 0;
  const hangVendorPercent = data && data.hangVendorPercent ? data.hangVendorPercent : 0;
  const vendorNum = data && data.vendorNum ? data.vendorNum : 0;

  // 退货
  const completeVendorNum = data && data.completeVendorNum ? data.completeVendorNum : 0;
  const completeVendorPercent = data && data.completeVendorPercent ? data.completeVendorPercent : 0;
  const unCompleteVendorNum = data && data.unCompleteVendorNum ? data.unCompleteVendorNum : 0;
  const unCompleteVendorPercent = data && data.unCompleteVendorPercent ? data.unCompleteVendorPercent : 0;
  const returnVendorNum = data && data.vendorNum ? data.vendorNum : 0;

  const legendData: Legend[] = [
    {
      label: '已完成供应商',
      iconColor: '#0BC4A7',
      num: finishVendorNum,
      percent: finishVendorPercent,
    },
    {
      label: '未完成供应商',
      iconColor: '#F2F2F2',
      color: '#2B2E3E',
      num: hangVendorNum,
      percent: hangVendorPercent,
    },
  ];

  const returnLegendData: Legend[] = [
    {
      label: '已完成供应商',
      iconColor: '#0BC4A7',
      num: completeVendorNum,
      percent: completeVendorPercent,
    },
    {
      label: '未完成供应商',
      iconColor: '#F2F2F2',
      color: '#2B2E3E',
      num: unCompleteVendorNum,
      percent: unCompleteVendorPercent,
    },
  ];

  const option = {
    tooltip: { show: false },
    title: { // 饼图中心文字第一行
      ...titleBaseOpts,
      text: `${finishVendorPercent}%`,
      textStyle: {
        ...titleBaseOpts.textStyle,
        color: '#0BC4A7',
      },
    },
    graphic: { // 饼图中心文字第二行
      ...graphicBaseOpts,
      style: {
        ...graphicBaseOpts.style,
        text: '已完成供应商',
      },
    },
    colorBy: 'data',
    animation: true,
    series: [
      basicPie,
      {
        ...realPieOpts,
        name: '供应商进度',
        color: [
          'rgba(255,255,255,0)',
          '#0BC4A7',
        ],
        data: [
          {
            value: hangVendorPercent,
            name: '未完成供应商',
          },
          {
            value: finishVendorPercent,
            name: '已完成供应商',
          },
        ],
      },
    ],
  };

  const returnOption = {
    tooltip: { show: false },
    title: { // 饼图中心文字第一行
      ...titleBaseOpts,
      text: `${completeVendorPercent}%`,
      textStyle: {
        ...titleBaseOpts.textStyle,
        color: '#0BC4A7',
      },
    },
    graphic: { // 饼图中心文字第二行
      ...graphicBaseOpts,
      style: {
        ...graphicBaseOpts.style,
        text: '已完成供应商',
      },
    },
    colorBy: 'data',
    animation: true,
    series: [
      basicPie,
      {
        ...realPieOpts,
        name: '供应商进度',
        color: [
          'rgba(255,255,255,0)',
          '#0BC4A7',
        ],
        data: [
          {
            value: unCompleteVendorPercent,
            name: '未完成供应商',
          },
          {
            value: completeVendorPercent,
            name: '已完成供应商',
          },
        ],
      },
    ],
  };
  return (
    <div className={styles.chartsArea}>
      <div className={styles.chartsTitle}>
        供货商进度
      </div>
      <div className={styles.chartsAndLegend}>
        <ReactECharts
          lazyUpdate
          notMerge
          option={activeKey === '1' ? option : returnOption}
          style={ReactEChartsStyle}
        />
        <LegendData
          data={activeKey === '1' ? legendData : returnLegendData}
          title={`供应商数 ${activeKey === '1' ? vendorNum : returnVendorNum }家`}
          unit="家"
        />
      </div>
    </div>
  );
});

export { VendorPieCharts };
