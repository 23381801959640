import { observer } from 'mobx-react';
import React from 'react';
import type { Legend } from '../../interface';
import styles from './index.less';

interface Props {
  title: string;
  unit?: string;
  data: Legend[];
}

const LegendData = observer((props: Props) => {
  const { title, data, unit = '人' } = props;

  return (
    <div className={styles.legend}>
      <div className={styles.userNum}>
        {title}
      </div>
      {data.map((el) => {
        return (
          <div
            className={styles.legendItem}
            key={el.label}
          >
            <div
              className={styles.icon}
              style={{ backgroundColor: el.iconColor }}
            />
            <span className={styles.label}>
              {el.label}
            </span>
            <span className={styles.num}>
              {el.num}
              {unit}
            </span>
            <span>
              占比
            </span>
            <span
              className={styles.percentNum}
              style={{ color: el.color ? el.color : el.iconColor }}
            >
              {el.percent}
              %
            </span>
          </div>
        );
      })}
    </div>
  );
});

export { LegendData };
