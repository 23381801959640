import ReactECharts from 'echarts-for-react';
import { observer } from 'mobx-react';
import React from 'react';
import type { OrderStatistics, Legend, ReturnOrderStatistics } from '../../interface';
import { LegendData } from '../legendData/index';
import { realPieOpts, ReactEChartsStyle, basicPie } from './baseOptions';
import styles from './index.less';

interface Props {
  data: Partial<OrderStatistics & ReturnOrderStatistics>;
  activeKey: '1' | '2'; // 1拿货，2退货
}

const OrderPieCharts = observer((props: Props) => {
  const { data, activeKey } = props;

  // 拿货
  const doneOrderNum = data && data.doneOrderNum ? data.doneOrderNum : 0;
  const doneOrderPercent = data && data.doneOrderPercent ? data.doneOrderPercent : 0;
  const putBarOrderNum = data && data.putBarOrderNum ? data.putBarOrderNum : 0;
  const putBarOrderPercent = data && data.putBarOrderPercent ? data.putBarOrderPercent : 0;
  const unHandleOrderNum = data && data.unHandleOrderNum ? data.unHandleOrderNum : 0;
  const unHandleOrderPercent = data && data.unHandleOrderPercent ? data.unHandleOrderPercent : 0;
  const orderNum = data && data.orderNum ? data.orderNum : 0;

  // 退货
  const confirmOrderNum = data && data.confirmOrderNum ? data.confirmOrderNum : 0;
  const confirmPercent = data && data.confirmPercent ? data.confirmPercent : 0;
  const returnOrderNum = data && data.orderNum ? data.orderNum : 0;
  const returnedNum = data && data.returnedNum ? data.returnedNum : 0;
  const returnedPercent = data && data.returnedPercent ? data.returnedPercent : 0;
  const waitReturnNum = data && data.waitReturnNum ? data.waitReturnNum : 0;
  const waitReturnPercent = data && data.waitReturnPercent ? data.waitReturnPercent : 0;

  const legendData: Legend[] = [
    {
      label: '已取货',
      iconColor: '#0BC4A7',
      num: doneOrderNum,
      percent: doneOrderPercent,
    },
    {
      label: '已放置条码',
      iconColor: '#1978FF',
      num: putBarOrderNum,
      percent: putBarOrderPercent,
    },
    {
      label: '待放条码',
      iconColor: '#FB7307',
      num: unHandleOrderNum,
      percent: unHandleOrderPercent,
    },
  ];

  const returnLegendData: Legend[] = [
    {
      label: '待退货',
      iconColor: '#0BC4A7',
      num: waitReturnNum,
      percent: waitReturnPercent,
    },
    {
      label: '已退货',
      iconColor: '#1978FF',
      num: returnedNum,
      percent: returnedPercent,
    },
    {
      label: '已确认数量',
      iconColor: '#FB7307',
      num: confirmOrderNum,
      percent: confirmPercent,
    },
  ];

  const option = {
    tooltip: { show: false },
    colorBy: 'data',
    animation: true,
    overflow: 'truncate',
    series: [
      basicPie,
      {
        ...realPieOpts,
        name: '拿货单进度',
        label: { show: false },
        color: [
          '#0BC4A7',
          '#1978FF',
          '#FB7307',
        ],
        data: [
          {
            value: doneOrderPercent,
            name: '已取货',
          },
          {
            value: putBarOrderPercent,
            name: '已放置条码',
          },
          {
            value: unHandleOrderPercent,
            name: '待放条码',
          },
        ],
      },
    ],
  };

  const returnOption = {
    tooltip: { show: false },
    colorBy: 'data',
    animation: true,
    overflow: 'truncate',
    series: [
      basicPie,
      {
        ...realPieOpts,
        name: '退货单进度',
        label: { show: false },
        color: [
          '#0BC4A7',
          '#1978FF',
          '#FB7307',
        ],
        data: [
          {
            value: waitReturnPercent,
            name: '待退货',
          },
          {
            value: returnedPercent,
            name: '已退货',
          },
          {
            value: confirmPercent,
            name: '已确认数量',
          },
        ],
      },
    ],
  };
  return (
    <div className={styles.chartsArea}>
      <div className={styles.chartsTitle}>
        {activeKey === '1' ? '拿货单进度' : '退货单进度'}
      </div>
      <div className={styles.chartsAndLegend}>
        <ReactECharts
          lazyUpdate
          notMerge
          option={activeKey === '1' ? option : returnOption}
          style={ReactEChartsStyle}
        />
        <LegendData
          data={activeKey === '1' ? legendData : returnLegendData}
          title={activeKey === '1' ? `拿货单数 ${orderNum}单` : `退货单数 ${returnOrderNum}单`}
          unit="单"
        />
      </div>
    </div>
  );
});

export { OrderPieCharts };
