import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { action, observable } from 'mobx';
import moment from 'moment';
import { api } from '../../../utils';
import type { HeadOverview, UserStatistics, OrderStatistics, VendorStatistics, OutlierSkuStatistics,
  ReturnTotalStatic, ReturnOrderStatistics, ReturnUserStatistics, ReturnVendorStatistics } from './interface';

class DashboardStore {
  @observable public tabActiveKey = '1';

  @observable public totalStatic = []; // 头部统计数据

  @observable public returnTotalStatic = []; // 退货头部统计数据

  @observable public date = moment().format('YYYY-MM-DD'); // 日期

  @observable public returnGoodsDate = moment().format('YYYY-MM-DD'); // 退货日期

  @observable public userProcess: UserStatistics; // 拿货员进度

  @observable public orderProcess: OrderStatistics; // 供货单进度

  @observable public vendorProcess: VendorStatistics; // 供应商进度

  @observable public outlierSku: OutlierSkuStatistics; // 异常sku

  @observable public returnUserProcess: ReturnUserStatistics; // 退货员进度

  @observable public returnOrderProcess: ReturnOrderStatistics; // 退货单进度

  @observable public returnVendorProcess: ReturnVendorStatistics; // 供应商退货进度

  @action public onQuery = (): void => {
    this.getHeadOverview();
    this.getUserProcess();
    this.getVendorProcess();
    this.getOrderProcess();
    this.getOutlierSku();
  };

  public queryReturnData = (): void => {
    this.getReturnHeadOverview();
    this.getReturnOrderProcess();
    this.getReturnUserProcess();
    this.getReturnVendorProcess();
  };

  @action public onTabActiveChange = (key: string): void => {
    this.tabActiveKey = key;
  };

  @action public onDateChange = (date, dateString: string): void => {
    this.date = dateString;
    this.onQuery();
  };

  @action public onReturnDateChange = (date, dateString: string): void => {
    this.returnGoodsDate = dateString;
    this.queryReturnData();
  };

  @action public getHeadOverview = async(): Promise<void> => {
    const res: BaseData<HeadOverview> = await request({ url: `${api.headOverview}?time=${this.date}` });
    const { cloudWarehouseNum, marketNum, vendorNum, pickUpOrderNum, skuNum, outlierSkuNum } = res.data;
    this.totalStatic = [
      {
        title: '派单网仓(家)',
        key: 'cloudWarehouseNum',
        number: cloudWarehouseNum || 0,
      },
      {
        title: '涉及市场',
        key: 'marketNum',
        number: marketNum || 0,
      },
      {
        title: '供应商(家)',
        key: 'vendorNum',
        number: vendorNum || 0,
      },
      {
        title: '拿货单数',
        key: 'pickUpOrderNum',
        number: pickUpOrderNum || 0,
      },
      {
        title: '商品件数',
        key: 'skuNum',
        number: skuNum || 0,
      },
      {
        title: '异常sku数',
        key: 'outlierSkuNum',
        number: outlierSkuNum || 0,
      },
    ];
  };

  @action public getUserProcess = async(): Promise<void> => {
    const res: BaseData<UserStatistics> = await request({ url: `${api.userProcess}?time=${this.date}` });
    this.userProcess = res.data;
  };

  @action public getVendorProcess = async(): Promise<void> => {
    const res: BaseData<VendorStatistics> = await request({ url: `${api.vendorProcess}?time=${this.date}` });
    this.vendorProcess = res.data;
  };

  @action public getOrderProcess = async(): Promise<void> => {
    const res: BaseData<OrderStatistics> = await request({ url: `${api.orderProcess}?time=${this.date}` });
    this.orderProcess = res.data;
  };

  @action public getOutlierSku = async(): Promise<void> => {
    const res: BaseData<OutlierSkuStatistics> = await request({ url: `${api.outlierSku}?time=${this.date}` });
    this.outlierSku = res.data;
  };

  @action public getReturnHeadOverview = async(): Promise<void> => {
    const res: BaseData<ReturnTotalStatic> = await request({ url: `${api.returnHeaderOverview }?time=${this.returnGoodsDate}` });
    const { cloudWarehouseNum, marketNum, vendorNum, returnOrderNum, skuNum } = res.data;
    this.returnTotalStatic = [
      {
        title: '派单网仓(家)',
        key: 'cloudWarehouseNum',
        number: cloudWarehouseNum || 0,
      },
      {
        title: '涉及市场',
        key: 'marketNum',
        number: marketNum || 0,
      },
      {
        title: '供应商(家)',
        key: 'vendorNum',
        number: vendorNum || 0,
      },
      {
        title: '退货单数',
        key: 'returnOrderNum',
        number: returnOrderNum || 0,
      },
      {
        title: '商品件数',
        key: 'skuNum',
        number: skuNum || 0,
      },
    ];
  };

  @action public getReturnOrderProcess = async(): Promise<void> => {
    const res: BaseData<ReturnOrderStatistics> = await request({ url: `${api.returnOrderProcess}?time=${this.returnGoodsDate}` });
    this.returnOrderProcess = res.data;
  };

  @action public getReturnUserProcess = async(): Promise<void> => {
    const res: BaseData<ReturnUserStatistics> = await request({ url: `${api.returnUserProcess}?time=${this.returnGoodsDate}` });
    this.returnUserProcess = res.data;
  };

  @action public getReturnVendorProcess = async(): Promise<void> => {
    const res: BaseData<ReturnVendorStatistics> = await request({ url: `${api.returnVendorProcess}?time=${this.returnGoodsDate}` });
    this.returnVendorProcess = res.data;
  };
}
export default DashboardStore;
