
import { DatePicker, Tabs } from 'antd';
import { Provider, observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import styles from './index.less';
import DashboardStore from './store';
import { OrderPieCharts } from './widget/pieCharts/orderPieCharts';
import { SkuPieCharts } from './widget/pieCharts/skuPieCharts';
import { UserPieCharts } from './widget/pieCharts/userPieCharts';
import { VendorPieCharts } from './widget/pieCharts/vendorPieCharts';

const { TabPane } = Tabs;
const dashboardStore = new DashboardStore();

const tabs = [
  {
    key: '1',
    tab: '拿货',
  },
  {
    key: '2',
    tab: '退货',
  },
];

interface IProps {
  store: DashboardStore;
}
@observer
export class Dashboard extends React.Component {
  componentDidMount(): void {
    dashboardStore.onQuery();
    dashboardStore.queryReturnData();
  }

  render(): JSX.Element {
    const { onTabActiveChange, tabActiveKey } = dashboardStore;

    const items = tabs.map((item) => ({
      label: item.tab,
      key: item.key,
    }));
    return (
      <div className={styles.dashboard}>
        <Provider dashboardStore={dashboardStore}>
          <Tabs
            activeKey={tabActiveKey}
            items={items}
            onTabClick={onTabActiveChange}
          >
            {/* {tabs.map((el) => {
              return (
                <TabPane
                  key={el.key}
                  tab={el.tab}
                />
              );
            })} */}
          </Tabs>
          {tabActiveKey === '1' ? <GetGoods store={dashboardStore}/> : <ReturnGoods store={dashboardStore}/>}
        </Provider>
      </div>
    );
  }
}

const ReturnGoods: React.FC<IProps> = observer((props) => {
  const { returnTotalStatic, returnOrderProcess, returnUserProcess, returnVendorProcess, onReturnDateChange } = props.store;
  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };
  return (
    <div className={styles.content}>
      <div>
        日期：
        {/* @ts-ignore */}
        <DatePicker
          allowClear={false}
          className={styles.date}

          // @ts-ignore
          defaultValue={moment()}
          disabledDate={disabledDate}
          onChange={onReturnDateChange}
        />
      </div>
      <div className={styles.totalStatic}>
        {returnTotalStatic.map((item) => {
          return (
            <div
              className={styles.staticItem}
              key={item.key}
            >
              <span className={styles.title}>
                {item.title}
              </span>
              <span className={styles.number}>
                {item.number}
              </span>
            </div>
          );
        })}
      </div>
      <div className={styles.panel}>
        <OrderPieCharts
          activeKey="2"
          data={returnOrderProcess}
        />
        <UserPieCharts
          activeKey="2"
          data={returnUserProcess}
        />
        <VendorPieCharts
          activeKey="2"
          data={returnVendorProcess}
        />
      </div>
    </div>
  );
});

const GetGoods: React.FC<IProps> = observer((props) => {
  const { date, totalStatic, userProcess, vendorProcess, orderProcess, outlierSku, onDateChange } = props.store;
  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };
  return (
    <div className={styles.content}>
      <div>
        日期：
        {/* @ts-ignore */}
        <DatePicker
          allowClear={false}
          className={styles.date}

          // @ts-ignore
          defaultValue={moment()}
          disabledDate={disabledDate}
          onChange={onDateChange}
        />
      </div>
      <div className={styles.totalStatic}>
        {totalStatic.map((item) => {
          return (
            <div
              className={styles.staticItem}
              key={item.key}
            >
              <span className={styles.title}>
                {item.title}
              </span>
              <span className={styles.number}>
                {item.number}
              </span>
            </div>
          );
        })}
      </div>
      <div className={styles.panel}>
        <OrderPieCharts
          activeKey="1"
          data={orderProcess}
        />
        <UserPieCharts
          activeKey="1"
          data={userProcess}
        />
        <VendorPieCharts
          activeKey="1"
          data={vendorProcess}
        />
        <SkuPieCharts
          data={outlierSku}
          time={date}
        />
      </div>
    </div>
  );
});

export default observer(Dashboard);
